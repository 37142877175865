import React from "react";
import Slider from "react-slick";
import Pagetitle from "../elements/Pagetitle";
import Testimonial from "../elements/Testimonial";

const sliderData = [
  {
    id: 1,
    avatar: "images/avatar-2.svg",
    userName: "Giấu tên",
    subtitle: "Quản lý tại X",
    review:
      "Có lượng kiến thức rất rộng và sâu ở nhiều lĩnh vực khác nhau. Mình rất yên tâm khi được làm việc cùng Thái.",
  },
  {
    id: 2,
    avatar: "images/avatar-1.svg",
    userName: "Giấu tên",
    subtitle: "Nhà đầu tư",
    review:
      "Nhờ Thái mà mình đã x2.5 tài khoản đầu tư trong vòng 1 tháng, Thái nói trong đó có phần may mắn, tuy nhiên đã vượt mức kì vọng ban đầu của mình.",
  },

  {
    id: 3,
    avatar: "images/avatar-3.svg",
    userName: "Giấu tên",
    subtitle: "Admin tại X",
    review:
      "Marketing rất giỏi và rất thông minh khi làm việc, đưa ra nhiều giải pháp hay và cực kì sáng tạo. Phải nói là ở Việt Nam rất ít người trẻ như em mà giỏi như vậy.",
  },
  {
    id: 4,
    avatar: "images/avatar-2.svg",
    userName: "Giấu tên",
    subtitle: "Ca sĩ",
    review:
      "Thái đã viết cho mình ứng dụng phần mềm phục vụ cho marketing ngay từ khi còn là học sinh cấp 2. Đúng là tuổi trẻ, tài cao. 👏",
  },
  {
    id: 5,
    avatar: "images/avatar-1.svg",
    userName: "Giấu tên",
    subtitle: "Đầu tư CryptoCurrency",
    review:
      "Theo dõi Thái được gần 1 năm trên Facebook, học hỏi được rất nhiều về tư duy đầu tư trên nhiều lĩnh vực 👍",
  },
  {
    id: 6,
    avatar: "images/avatar-2.svg",
    userName: "Giấu tên",
    subtitle: "Chủ doanh nghiệp",
    review:
      "Thái đã giúp công ty chúng tôi phát triển hệ thống quản lý nội bộ, giúp tăng năng suất làm việc lên đáng kể. Cậu ấy thực sự là một lập trình viên tài năng.",
  },
  {
    id: 7,
    avatar: "images/avatar-3.svg",
    userName: "Giấu tên",
    subtitle: "Nhà đầu tư BĐS",
    review:
      "Tôi đã học được rất nhiều kiến thức và kinh nghiệm đầu tư từ Thái. Những chia sẻ của cậu ấy luôn sâu sắc và thực tế.",
  },
  {
    id: 8,
    avatar: "images/avatar-1.svg",
    userName: "Giấu tên",
    subtitle: "Founder startup",
    review:
      "Thái đã tư vấn giúp startup của chúng tôi xây dựng chiến lược phát triển sản phẩm và marketing hiệu quả. Cậu ấy thực sự hiểu rõ thị trường và công nghệ.",
  },
  {
    id: 9,
    avatar: "images/avatar-2.svg",
    userName: "Giấu tên",
    subtitle: "Giảng viên đại học",
    review:
      "Là một giảng viên, tôi rất ấn tượng với kiến thức và tư duy logic của Thái. Cậu ấy chắc chắn sẽ còn tiến xa hơn nữa trong tương lai.",
  },
  {
    id: 10,
    avatar: "images/avatar-3.svg",
    userName: "Giấu tên",
    subtitle: "Chuyên viên tài chính",
    review:
      "Thái đã chỉ cho tôi thấy những cơ hội đầu tư mà tôi chưa từng nghĩ tới. Những lời khuyên của cậu ấy giúp tôi ra quyết định đúng đắn và sinh lời tốt.",
  },
];

function Testimonials() {
  const slidetSettings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section id="testimonials">
      <div className="container">
        <Pagetitle title="Phản hồi tích cực" />
        <Slider {...slidetSettings} className="testimonials-wrapper">
          {sliderData.map((slider) => (
            <Testimonial key={slider.id} slider={slider} />
          ))}
        </Slider>
      </div>
    </section>
  );
}

export default Testimonials;
