import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import HomepageLight from "./pages/HomepageLight";
import MePage from "./pages/MePage";
import BookingPage from "./pages/BookingPage";
import HomepageDark from "./pages/HomepageDark";
import "./App.scss";
import OrderBookPage from "./pages/OrderBookPage";
import IframeEmbed from "./pages/IframeEmbed";
import { Redirect } from "react-router-dom";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/dark" exact>
          <HomepageDark />
        </Route>
        <Route path="/light" exact>
          <HomepageLight />
        </Route>
        <Route path="/me" exact>
          <MePage />
        </Route>
        <Route path="/booking">
          <BookingPage />
        </Route>
        <Route path="/order-book">
          <OrderBookPage />
        </Route>
        <Route path="/baiviet/mindset-crypto">
          <IframeEmbed url="https://ends-jog-fck.craft.me/LeHongThaiReal-Mindset" />
        </Route>
        <Route
          path="/invite/binance"
          component={() => {
            window.location.href =
              "https://www.binance.com/en/register?ref=19857765";
            return null;
          }}
        ></Route>

        <Route exact path="/">
          <Homepage />
        </Route>
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
