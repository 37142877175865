import React from "react";
import TrackVisibility from "react-on-screen";
import Counter from "../elements/Counter";
import Pagetitle from "../elements/Pagetitle";
import Skill from "../elements/Skill";

const aboutContent = {
  name: "Thái",
  avatarImage: "/images/avatar-2.svg",
  // content: `Tôi là Thái, một chuyên gia đầu tư tài chính và công nghệ đến từ TP. HCM. Với ${
  //   new Date().getFullYear() - 2014
  // } năm kinh nghiệm trên thị trường Crypto Currency và nền tảng vững chắc về khoa học, tôi mang đến cái nhìn sâu sắc về xu hướng thị trường, những chiến lược đầu tư thông minh, hiệu quả. Với phân tích xu hướng, ứng dụng công nghệ tiên tiến và quản trị rủi ro, tôi hỗ trợ nhà đầu tư tối ưu lợi nhuận.`,
  content: `Tôi là Thái, chuyên gia đầu tư Crypto và công nghệ đến từ TP.HCM. ${
    new Date().getFullYear() - 2014
  } năm kinh nghiệm cùng nền tảng khoa học vững chắc giúp tôi đưa ra chiến lược đầu tư thông minh. Bằng phân tích xu hướng, ứng dụng công nghệ và quản trị rủi ro, tôi hỗ trợ nhà đầu tư tối ưu lợi nhuận trên thị trường biến động.`,
};

const progressData = [
  {
    id: 1,
    title: "Tài chính",
    percantage: 85,
    progressColor: "#FFD15C",
  },
  {
    id: 2,
    title: "Công nghệ",
    percantage: 95,
    progressColor: "#FF4C60",
  },
  {
    id: 3,
    title: "Quản trị",
    percantage: 80,
    progressColor: "#5AECA8",
  },
  {
    id: 4,
    title: "Kinh doanh",
    percantage: 75,
    progressColor: "#6C6CE5",
  },
];

const counterData = [
  {
    id: 1,
    title: "Dự án hoàn thành",
    count: 23,
    icon: "icon-fire",
  },
  {
    id: 2,
    title: "Nhà đầu tư theo dõi",
    count: 4768,
    icon: "icon-people",
  },
  {
    id: 3,
    title: "Khách hàng hài lòng",
    count: 25121,
    icon: "icon-like",
  },
  {
    id: 4,
    title: "Danh hiệu",
    count: 25,
    icon: "icon-badge",
  },
];

function About() {
  return (
    <section id="about">
      <div className="container">
        <Pagetitle title="Giới thiệu" />
        <div className="row">
          <div className="col-md-3">
            <div className="text-center text-md-left">
              <img src={aboutContent.avatarImage} alt={aboutContent.name} />
            </div>
            <div className="spacer d-md-none d-lg-none" data-height="30"></div>
          </div>

          <div className="col-md-9 triangle-left-md triangle-top-sm">
            <div className="rounded bg-white shadow-dark padding-30">
              <div className="row">
                <div className="col-md-6">
                  <p>{aboutContent.content}</p>
                  <div className="mt-3">
                    <a href="/me" className="btn btn-default">
                      Tìm hiểu thêm
                    </a>
                  </div>
                  <div
                    className="spacer d-md-none d-lg-none"
                    data-height="30"
                  ></div>
                </div>
                <div className="col-md-6">
                  {progressData.map((progress) => (
                    <TrackVisibility
                      once
                      key={progress.id}
                      className="progress-wrapper"
                    >
                      <Skill progress={progress} />
                    </TrackVisibility>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer" data-height="70"></div>
        <div className="row fix-spacing d-flex justify-content-center align-items-center">
          {counterData.map((counter) => (
            <div key={counter.id} className="col-md-3 col-6">
              <TrackVisibility once>
                <Counter counterItem={counter} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default About;
