import React, { useState } from "react";
import ReactCursorPosition from "react-cursor-position";
import { Element } from "react-scroll";
import Header from "../components/layouts/Header";
import About from "../components/sections/About";
// import Blogs from "../components/sections/Blogs";
// import Brandlogos from "../components/sections/Brandlogos";
import Contact from "../components/sections/Contact";
import Herosection from "../components/sections/Herosection";
// import Experiences from "../components/sections/Experiences";
// import Pricing from "../components/sections/Pricing";
import Services from "../components/sections/Services";
import Testimonials from "../components/sections/Testimonials";
// import Works from "../components/sections/Works";

const handleAppearance = () => {
  // Auto DarkMode
  if (
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    // dark mode
    document.body.classList.add("dark");
    let title = document.querySelector(
      "#services > div > div.row.fix-spacing > div:nth-child(2) > div > h3"
    );
    let description = document.querySelector(
      "#services > div > div.row.fix-spacing > div:nth-child(2) > div > p"
    );
    title.style.color = description.style.color = "#5E5C7F";
  } else {
    document.body.classList.remove("dark");
  }
};

function Homepage() {
  React.useEffect(() => {
    handleAppearance();
    window
      .matchMedia("(prefers-color-scheme: dark)")
      .addEventListener("change", () => {
        handleAppearance();
      });
  }, []);

  const [toggleMenu, setToggleMenu] = useState(false);

  const headerToggler = (e) => {
    e.preventDefault();
    setToggleMenu(!toggleMenu);
  };

  document.addEventListener("click", function (e) {
    if (e.target.closest(".content")) {
      setToggleMenu(false);
    }
  });

  return (
    <>
      <Header
        logoSource="/images/logo.svg"
        toggleMenu={toggleMenu}
        headerToggler={headerToggler}
      />
      <main className={toggleMenu ? "content open" : "content"}>
        <Element name="section-home">
          <ReactCursorPosition>
            <Herosection />
          </ReactCursorPosition>
        </Element>
        <Element name="section-about">
          <About />
        </Element>
        <Element name="section-services">
          <Services />
        </Element>
        {/* <Element name="section-experiences">
          <Experiences />
        </Element> */}
        {/* <Element name="section-works">
          <Works />
        </Element> */}
        {/* <Element name="section-pricing">
          <Pricing />
        </Element> */}
        <Element name="section-testimoninal">
          <Testimonials />
        </Element>
        {/* <Element name="section-brandlogos">
          <Brandlogos />
        </Element> */}
        {/* <Element name="section-blogs">
          <Blogs />
        </Element> */}
        <Element name="section-contact">
          <Contact />
        </Element>
        <div className="spacer" data-height="96"></div>
      </main>
    </>
  );
}

export default Homepage;
