import React, { useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import Pagetitle from "../elements/Pagetitle";

function Contact() {
  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [messageBtn, setMessageBtn] = useState(true);

  const submitHandler = (event) => {
    event.preventDefault();
    if (!formdata.name) {
      setError(true);
      setMessage("⚠️ Vui lòng nhập tên.");
    } else if (!formdata.email) {
      setError(true);
      setMessage("⚠️ Vui lòng nhập Email.");
    } else if (!formdata.subject) {
      setError(true);
      setMessage("⚠️ Vui lòng nhập tiêu đề.");
    } else if (!formdata.message) {
      setError(true);
      setMessage("⚠️ Vui lòng nhập nội dung.");
    } else {
      setError(false);
      setMessageBtn(false);

      setMessage(
        "Tin nhắn đã được gửi đi, thông thường sẽ trả lời trong vòng 24h. Cảm ơn bạn!"
      );
      let message = `💌 Liên hệ từ website lehongthai.vip\nEmail: ${formdata.email}\n\nTên: ${formdata.name}\nTiêu đề: ${formdata.subject}\nNội dung: ${formdata.message}`;
      fetch(
        `https://api.telegram.org/bot782377690:AAEo5rJ6XYrsL9lna2kErvJkAGvirSZEQzs/sendMessage?chat_id=548860872&text=${encodeURIComponent(
          message
        )}`
      )
        .then((response) => response.json())
        .then((data) => console.log("ok đã gửi thông tin"));

      setTimeout(() => {
        setMessageBtn(true);
        setMessage("");
      }, 10 * 1000);
    }
  };

  const handleChange = (event) => {
    setFormdata({
      ...formdata,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const handleAlerts = () => {
    if (error && message) {
      return <div className="alert alert-danger mt-4">{message}</div>;
    } else if (!error && message) {
      return <div className="alert alert-success mt-4">{message}</div>;
    } else {
      return null;
    }
  };

  return (
    <section id="contact">
      <div className="container">
        <Pagetitle title="Liên hệ" />

        <div className="row">
          <div className="col-md-4">
            <div className="contact-info">
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <h3>Hãy nói cho tôi bất cứ điều gì!</h3>
              </ScrollAnimation>
              <ScrollAnimation
                animateIn="fadeInUp"
                animateOut="fadeInOut"
                animateOnce={true}
              >
                <p>
                  Liên hệ Thái qua{" "}
                  <a href="mailto:homthucuathai@gmail.com">email</a> để được ưu
                  tiên. 👋
                </p>
              </ScrollAnimation>
            </div>
          </div>

          <div className="col-md-8">
            <form
              id="contact-form"
              className="contact-form mt-6"
              onSubmit={submitHandler}
            >
              <div className="row">
                <div className="column col-md-6">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="InputName"
                      placeholder="Tên đầy đủ"
                      onChange={handleChange}
                      value={formdata.name}
                    />
                  </div>
                </div>

                <div className="column col-md-6">
                  <div className="form-group">
                    <input
                      type="email"
                      className="form-control"
                      id="InputEmail"
                      name="email"
                      placeholder="Email"
                      onChange={handleChange}
                      value={formdata.email}
                    />
                  </div>
                </div>

                <div className="column col-md-12">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      id="InputSubject"
                      name="subject"
                      placeholder="Tiêu đề"
                      onChange={handleChange}
                      value={formdata.subject}
                    />
                  </div>
                </div>

                <div className="column col-md-12">
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="InputMessage"
                      className="form-control"
                      rows="5"
                      placeholder="Nội dung"
                      onChange={handleChange}
                      value={formdata.message}
                    ></textarea>
                  </div>
                </div>
              </div>
              {messageBtn && (
                <button
                  type="submit"
                  name="submit"
                  id="submit"
                  value="Submit"
                  className="btn btn-default"
                  style={
                    messageBtn ? null : { backgroundColor: "rgb(130 130 140)" }
                  }
                  disabled={!messageBtn}
                >
                  Gửi tin nhắn
                </button>
              )}
            </form>
            {handleAlerts()}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Contact;
