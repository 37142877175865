import React from "react";
import { Link } from "react-scroll";
import Pagetitle from "../elements/Pagetitle";
import Service from "../elements/Service";

const servicesData = [
  {
    id: 1,
    icon: "images/service-1.svg",
    title: "💰 Đầu tư tài chính",
    content:
      "Bạn muốn tìm hiểu về đầu tư tài chính và cách sinh lời hiệu quả trong từng thời điểm? Hãy để Thái chia sẻ kinh nghiệm quý báu, giúp bạn đạt được mục tiêu tài chính mơ ước. Tham gia buổi chia sẻ, thảo luận cùng Thái để nắm bắt cơ hội!",
    color: "#6C6CE5",
    contentColor: "light",
  },
  {
    id: 2,
    icon: "images/service-2.svg",
    title: "🎨 Giải pháp công nghệ",
    content:
      "Đội ngũ lập trình cung cấp dịch vụ phát triển ứng dụng và website, giúp tối ưu hóa và tự động hóa quy trình. Với kinh nghiệm và công nghệ hiện đại, chúng tôi mang đến giải pháp toàn diện, giúp doanh nghiệp vận hành hiệu quả.",
    color: "#F9D74C",
    contentColor: "dark",
  },
  {
    id: 3,
    icon: "images/service-3.svg",
    title: "🎉 Ý tưởng “điên rồ”? Cùng Thái biến nó thành sự thật!",
    content:
      "Bạn có một ý tưởng táo bạo mà chưa ai nghĩ tới? Hay một dự án “không tưởng” mà bạn hằng ấp ủ? Đừng giữ nó cho riêng mình! Hãy chia sẻ ngay với Thái, và xem chúng ta có thể cùng nhau tạo nên điều gì đó phi thường. ",
    color: "#F97B8B",
    contentColor: "light",
  },
];

function Services() {
  return (
    <section id="services">
      <div className="container">
        <Pagetitle title="Công việc" />
        <div className="row fix-spacing">
          <div
            className="col-md-4"
            key={servicesData[0].id}
            onClick={() => (window.location.href = "/booking")}
            style={{ cursor: "pointer" }}
          >
            <Service service={servicesData[0]} />
          </div>
          <div
            className="col-md-4"
            key={servicesData[1].id}
            to="section-contact"
            style={{ cursor: "pointer" }}
            spy={true}
            smooth={true}
            duration={500}
          >
            <Service service={servicesData[1]} styles={{ color: "#5E5C7F" }} />
          </div>
          <Link
            className="col-md-4"
            key={servicesData[2].id}
            to="section-contact"
            style={{ cursor: "pointer" }}
            spy={true}
            smooth={true}
            duration={500}
          >
            <Service service={servicesData[2]} />
          </Link>
        </div>
        <div className="mt-5 text-center">
          <p className="mb-0">
            Bạn có yêu cầu đặc biệt nào khác?{" "}
            <Link
              className="colorpink pointer"
              to="section-contact"
              spy={true}
              smooth={true}
              duration={500}
            >
              Bấm vào đây
            </Link>{" "}
            để liên hệ với Thái! 👋
          </p>
        </div>
      </div>
    </section>
  );
}

export default Services;
