import React from "react";
// import PropTypes from "prop-types";

const IframeEmbed = ({ url }) => {
  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        border: "none",
        margin: 0,
        padding: 0,
        overflow: "hidden",
        zIndex: 9999,
      }}
    >
      <iframe
        src={url}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
        frameBorder="0"
        allowFullScreen
        title="Embedded Iframe"
      />
    </div>
  );
};

// IframeEmbed.propTypes = {
//   url: PropTypes.string.isRequired,
// };

export default IframeEmbed;
